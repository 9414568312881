import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"
import { Container, Menu, Segment, Visibility } from "semantic-ui-react"

import { Media } from "../Media"

class DesktopContainer extends Component {
  state = {
    activeItem: this.props.node,
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ padding: "1em 0em" }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container
                fluid
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              >
                <Menu.Item header>
                  {this.props.siteTitle}
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/"
                  onClick={() => this.setState({ activeItem: "/" })}
                  active={this.state.activeItem === "/"}
                  position="right"
                >
                  Home
                </Menu.Item>
                <Menu.Item as={Link} to="/projects">
                  Projects
                </Menu.Item>
                <Menu.Item as={Link} to="/">
                  Company
                </Menu.Item>
                <Menu.Item as={Link} to="/">
                  Careers
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

export default DesktopContainer
