import { createMedia } from "@artsy/fresnel"

const IrisMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

export const mediaStyle = IrisMedia.createMediaStyle()
export const { Media, MediaContextProvider } = IrisMedia
