import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.scss"
import { MediaContextProvider } from "../Media"
import DesktopContainer from "./DesktopContainer"
import MobileContainer from "./MobileContainer"

const Layout = ({ node, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MediaContextProvider>
      <DesktopContainer siteTitle={data.site.siteMetadata.title} node={node}>
        {children}
      </DesktopContainer>
      <MobileContainer siteTitle={data.site.siteMetadata.title} node={node}>
        {children}
      </MobileContainer>
    </MediaContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
